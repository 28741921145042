import React from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField'
import LinearProgress from "@mui/material/LinearProgress";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Icon from '@mui/material/Icon';

import FacturaTable from '../components/FacturaTable';

const us = require("../utils/usodecfdi");

export default function OSF() {
    let { id } = useParams();
    let [loading, setLoading] = React.useState(true);
    let [error, setError] = React.useState(false);
    let [validacion, setValidacion] = React.useState({
        open: false,
        loading: false,
        status: 'pending',
        response: null,
    });
    let [orderData, setOD] = React.useState(null);
    let [temporales, setTemporales] = React.useState({
        tipo: "F",
        rfc: "",
        razonSocial: "",
        codigoPostal: "",
        validado: false,
        regimenFiscal: "616",
        tipoDePago: "31",
        usoCFDI: "S01",
        formularioDesactivado: false,
        taxRate: 0.16
    });

    let [validaciones, setValidaciones] = React.useState({
        rfc: false,
        razonSocial: false,
        codigoPostal: false,
        regimenFiscal: false,
        tipoDePago: false,
        usoCFDI: false
    });

    let [fetching, setFetching] = React.useState(false);
    let [snackbar, setSnackbar] = React.useState({
        open: false,
        severity: "success",
        message: ""
    });

    let controller = new AbortController();

    const regimenesFiscales = [
        { value: '601', label: 'General de Ley Personas Morales' },
        { value: '603', label: 'Personas Morales con Fines No Lucrativos' },
        { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
        { value: '606', label: 'Arrendamiento' },
        { value: '607', label: 'Regimen de Enajenacion o Adquisicion de Bienes' },
        { value: '608', label: 'Demas ingresos' },
        { value: '610', label: 'Residentes en el Extranjero sin Establecimiento Permanente en México'},
        { value: '611', label: 'Ingresos por Dividendos (socios y accionistas)'},
        { value: "612", label: 'Personas Físicas con Actividades Empresariales y Profesionales'},
        { value: "614", label: 'Ingresos por intereses'},
        { value: "615", label:'Régimen de los ingresos por obtención de premios'},
        { value: "616", label: 'Sin obligaciones fiscales'},
        { value: "620", label: 'Sociedades Cooperativas de Producción que optan por difererir sus ingresos'},
        { value: "621", label: 'Incorporación Fiscal'},
        { value: "622", label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'},
        { value: "623", label: 'Opcional para Grupos de Sociedades'},
        { value: "624", label: 'Coordinados'},
        { value: "625", label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'},
        { value: "626", label: 'Régimen Simplificado de Confianza'},
    ]

    const tiposDePago = [
        { value: '01', label: 'Efectivo' },
        { value: '02', label: 'Cheque nominativo' },
        { value: '03', label: 'Transferencia electrónica de fondos' },
        { value: '04', label: 'Tarjeta de crédito' },
        // { value: '05', label: 'Monedero electrónico' },
        { value: '06', label: 'Dinero electrónico' },
        // { value: '08', label: 'Vales de despensa' },
        // { value: '12', label: 'Dación en pago' },
        // { value: '13', label: 'Pago por subrogación' },
        // { value: '14', label: 'Pago por consignación' },
        // { value: '15', label: 'Condonación' },
        // { value: '17', label: 'Compensación' },
        // { value: '23', label: 'Novación' },
        // { value: '24', label: 'Confusión' },
        // { value: '25', label: 'Remisión de deuda' },
        // { value: '26', label: 'Prescripción o caducidad' },
        // { value: '27', label: 'A satisfacción del acreedor' },
        { value: '28', label: 'Tarjeta de débito' },
        // { value: '29', label: 'Tarjeta de servicios' },
        // { value: '30', label: 'Aplicación de anticipos' },
        { value: '31', label: 'Intermediario de pagos' },
    ]

    const usosCFDI = [
        { value: 'G01', label: 'Adquisición de mercancias' },
        { value: 'G02', label: 'Devoluciones, descuentos o bonificaciones' },
        { value: 'G03', label: 'Gastos en general' },
        { value: 'I02', label: 'Mobiliario y equipo de oficina por inversiones'},
        { value: 'I04', label: 'Equipo de computo y accesorios'},
        { value: 'I06', label: 'Comunicaciones telefónicas'},
        { value: 'I07', label: 'Comunicaciones satelitales'},
        { value: 'I08', label: 'Otra maquinaria y equipo'},
        { value: 'S01', label: 'Sin efectos fiscales'},
    ]

    React.useEffect(() => {
        document.title = `Cargando datos...`
        fetchOrderData();
    }, [id]);

    const fetchOrderData = async() => {
        fetch(`https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/orderDetails?orderId=${id}`, {
            signal: controller.signal
        })
            .then(res => res.json())
            .then(data => {
                if(data.status === "success") {
                    setOD(data.data);
                    setLoading(false);
                    let len = data.data.order_items.length
                    document.title = `Facturando ${len} ${(len > 1) ? "articulos" : "articulo"}`
                }
                if(data.message === "La factura ya existe") {
                    navigate(`/recuperar/${id}/${data.data._doc.facturaId}/${data.data._doc.RFC}`);
                }
                else {
                    setError(true);
                }
            })
            .catch(err => {
                document.title = "Facturación - Intente de nuevo"
                setError(true);
            });
        setTimeout(() => controller.abort(), 10000)
        
    }

    const alertar = (message, severity = "error") => {
        setSnackbar({
            open: true,
            severity: severity,
            message: message
        });
        setTimeout(() => {
            setSnackbar({
                ...snackbar,
                open: false
            });
        }, 3000);
    }

    const rfcGenerico = () => {
        alertar("RFC genérico, autocompletando campos", "warning");
        setTemporales({
            ...temporales,
            rfc: "XAXX010101000",
            razonSocial: "PUBLICO EN GENERAL",
            codigoPostal: "32674",
            valido: true,
            regimenFiscal: "616",
            usoCFDI: "S01",
            tipo: "F",
            tipoDePago: "31",
            taxRate: 0.08,
            formularioDesactivado: true
        })
        setValidacion({
            status: "success",
        })
    }

    const validarDatos = () => {
        console.log(temporales.rfc, temporales.razonSocial, temporales.codigoPostal)
        if(temporales.rfc !== "" && temporales.razonSocial !== "" && temporales.codigoPostal !== "") {
            setValidacion({
                ...validacion,
                loading: true
            })
            fetch(`https://voithos.vcano5.com/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    RFC: temporales.rfc,
                    RAZON_SOCIAL: temporales.razonSocial,
                    CODIGO_POSTAL: temporales.codigoPostal
                }),
                signal: controller.signal
            })
            .then(res => res.json())
            .then(data => {
                if(data.status === "success") {
                    setValidacion({
                        ...validacion,
                        loading: false,
                        status: "success",
                        response: data
                    });
                    alertar("Datos validados correctamente", "success");
                }
                else {
                    setValidacion({
                        ...validacion,
                        loading: false,
                        status: "error",
                        response: data
                    })
                    alertar(data.message, "error");
                    if(data.message === "El nombre, denominación o razón social no coincide con el registrado en el RFC") {
                        setTemporales({
                            ...temporales,
                            razonSocial: ""
                        })
                        setValidaciones({
                            ...validaciones,
                            razonSocial: true
                        })
                    }
                    else if(data.message === "EL Nombre o Razón Social y CP no coinciden con lo registrado en el RFC") {
                        setTemporales({
                            ...temporales,
                            razonSocial: "",
                            codigoPostal: ""
                        })
                        setValidaciones({
                            ...validaciones,
                            razonSocial: true,
                            codigoPostal: true
                        })
                    }
                    else if(data.message === "El Código Postal no coincide con el registrado en el RFC.") {
                        setTemporales({
                            ...temporales,
                            codigoPostal: ""
                        })
                        setValidaciones({
                            ...validaciones,
                            codigoPostal: true
                        })
                    }
                    else if(data.message === "RFC no registrado en el padrón de contribuyentes") {
                        setTemporales({
                            ...temporales,
                            rfc: ""
                        })
                        setValidaciones({
                            ...validaciones,
                            rfc: true
                        })
                    }
                }
            })
            .catch(err => {
                alertar("Error al validar datos. Intentelo de nuevo en 5 segundos", "error");
                setValidacion({
                    ...validacion,
                    loading: false,
                    status: "error",
                })
            });

            setTimeout(() => controller.abort(), 30000)
        }
        else {

        }
    }

    const fetchTaxRate = (codigoPostal) => {
        fetch(`https://gy7cqmzlfmxmzjixnhcoq7zzy40htihr.lambda-url.us-east-1.on.aws/taxRate?zipCode=${codigoPostal}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setTemporales({
                    ...temporales,
                    taxRate: data.taxRate
                })
            })
            .catch(err => {
                alertar("No se pudo obtener el impuesto, intente de nuevo");
            });
    }

    const navigate = useNavigate();

    const facturar = () => {
        if(!fetching) {
            setFetching(true)
            let payload = {
                orderId: id,
                rfc: temporales.rfc,
                razonSocial: temporales.razonSocial,
                fiscalRegime: temporales.regimenFiscal,
                cfdiUse: temporales.usoCFDI,
                paymentType: temporales.tipoDePago,
                zipCode: temporales.codigoPostal,
                taxRate: temporales.taxRate,
            }
            fetch('https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/osf', {
                method: "POST",
                // headers: {
                //     "Content-Type": "application/json"
                // },
                body: JSON.stringify(payload)
            })
            .then(response => response.json())
            .then(data => {
                let datos = JSON.parse(data.data);
                console.log(datos);
                navigate(`/recuperar/${id}/${datos.Id}/${datos.Receiver.Rfc}`)
            })
            .catch(err => {
                alertar("Error al facturar, intente de nuevo");
                setTimeout(() => {
                    navigate(`/m/${id}`)
                }, 1500)
                setFetching(false)
            });
        }
        
    }

    // Reg Exp
    const PersonaFisica = new RegExp(/[A-Z]{4}[0-9]{6}[A-Z0-9]{2}[0-9A]{1}/);
    const PersonaMoral = new RegExp(/[A-Z]{3}[0-9]{6}[A-Z0-9]{2}[0-9A]{1}/);

    // Handlers

    const handleSelectChange = (e) => {
        console.log(`${e.target.name} ${e.target.value}`)
        setTemporales({
            ...temporales,
            [e.target.name]: e.target.value
        });
    }

    const handleTextFieldBlur = (e) => {
        let {name: nombre, value: valor} = e.target;
        setTemporales({
            ...temporales,
            [nombre]: valor
        })
        if(nombre === "codigoPostal") {
            if(valor.length === 5) {
                fetchTaxRate(valor);
                setValidaciones({
                    ...validaciones,
                    "codigoPostal": false
                })
            }
            else {
                setValidaciones({
                    ...validaciones,
                    "codigoPostal": true
                })
                alertar("El código postal debe tener 5 dígitos");
            }
        }
        if(nombre === "razonSocial") {
            if(valor.length > 0) {
                if(valor.substring(valor.length - 1) === " ") {
                    setValidaciones({
                        ...validaciones,
                        "razonSocial": true
                    })
                    alertar("La razón social no debe terminar con un espacio");
                }
                else {
                    setValidaciones({
                        ...validaciones,
                        "razonSocial": false
                    })
                }
            }
            else {
                setValidaciones({
                    ...validaciones,
                    "razonSocial": true
                })
                alertar("La razón social no puede estar vacía");
            }
        }
        if(nombre === "rfc") {
            handleRFCBlur();
        }
    }

    const handleRFCChange = (e) => {
        if(e.target.value.length <= 13) {
            setTemporales({
                ...temporales,
                rfc: e.target.value.toUpperCase()
            })
        }
    }
    
    const handleRFCBlur = () => {
        // console.log(" called")
        let rfc = temporales.rfc;
        console.log(rfc.length);
        if(rfc.length === 12 || rfc.length === 13) {
            if(rfc.match(PersonaFisica)) {
                setTemporales({
                    ...temporales,
                    rfc: rfc,
                    tipo: "F",
                    formularioDesactivado: false
                });
                setValidaciones({
                    ...validaciones,
                    rfc: false 
                })
                if(rfc === "XAXX010101000" || rfc === "XEXX010101000") {
                    rfcGenerico();
                }
            }
            else if(rfc.match(PersonaMoral)) {
                setTemporales({
                    ...temporales,
                    rfc: rfc,
                    tipo: "M",
                    formularioDesactivado: false
                });
                setValidaciones({
                    ...validaciones,
                    rfc: false 
                })
            }
            else {
                setValidaciones({
                    ...validaciones,
                    rfc: true 
                })
                alertar("El RFC no es válido");
            }
        }
        else {
            setValidaciones({
                ...validaciones,
                rfc: true 
            })
            alertar("El RFC no es válido");
        }
    };
    
    if(!loading) {
        return (
            <Box sx={{maxWidth: '1024px', px: 2, borderRadius: 2}}>
                <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={validacion.loading}>
                    <Typography variant="body2">Esta operación puede tardar hasta 30 segundos</Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={snackbar.open} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}>
                    <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
                {/* TITULO */}
                <Typography variant="h4">Facturación Electrónica</Typography>
                <Typography variant="body1">Para poder facturar, necesitamos que nos proporciones los siguientes datos:</Typography>
                
                {/* FORMULARIO */}
                <Box sx={{my: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextField name="rfc" fullWidth label="RFC" variant="outlined" error={validaciones.rfc} value={temporales.rfc} onChange={handleRFCChange} onBlur={handleTextFieldBlur} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField name="codigoPostal" fullWidth label="Codigo postal" variant="outlined" type="tel" error={validaciones.codigoPostal} value={temporales.codigoPostal} onChange={(e) => setTemporales({...temporales, codigoPostal: e.target.value})} onBlur={handleTextFieldBlur} disabled={temporales.formularioDesactivado}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="razonSocial" fullWidth label="Razon social" variant="outlined" error={validaciones.razonSocial} value={temporales.razonSocial} onChange={(e) => setTemporales({...temporales, razonSocial: e.target.value.toUpperCase()})} onBlur={handleTextFieldBlur} disabled={temporales.formularioDesactivado}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="fiscal-regime-label">Regimen Fiscal</InputLabel>
                                <Select
                                    labelId="fiscal-regime-label"
                                    id="fiscal-regime-label"
                                    name="regimenFiscal"
                                    value={temporales.regimenFiscal}
                                    label="fiscal-regime-label"
                                    onChange={handleSelectChange}
                                    disabled={temporales.formularioDesactivado}
                                >
                                    {regimenesFiscales.map((uso, index) => {
                                        return (
                                            <MenuItem key={index} value={uso.value}>{uso.value} {uso.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="uso-cfdi-label">Uso CFDI</InputLabel>
                                <Select
                                    labelId="uso-cfdi-label"
                                    id="uso-cfdi-label"
                                    name="usoCFDI"
                                    value={temporales.usoCFDI}
                                    label="uso-cfdi-label"
                                    onChange={handleSelectChange}
                                    disabled={temporales.formularioDesactivado}
                                >
                                    {usosCFDI.map((uso, index) => {
                                        // console.log(us[uso.value].regimenesFiscales.includes(parseInt(temporales.regimenFiscal)))
                                        return (
                                            <MenuItem key={index} value={uso.value} disabled={
                                                !us[uso.value].regimenesFiscales.includes(parseInt(temporales.regimenFiscal))
                                            }>{uso.value} {uso.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="tipo-pago-label">Tipo de pago</InputLabel>
                                <Select
                                    labelId="tipo-pago-label"
                                    id="tipo-pago-label"
                                    value={temporales.tipoDePago}
                                    onChange={handleSelectChange}
                                    name="tipoDePago"
                                    label="tipo-pago-label"
                                >
                                    {tiposDePago.map((uso, index) => {
                                        return (
                                            <MenuItem key={index} value={uso.value}>{uso.value} {uso.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <FacturaTable data={orderData["order_items"]} taxRate={temporales.taxRate} />

                <Box sx={{my: 2}}>
                    <Grid container spacing={2}>
                        {(validacion.status !== "success") ? (
                            <Grid item xs={12}>
                                <Button variant="contained" color="secondary" fullWidth onClick={validarDatos} disabled={!((temporales.rfc !== "" && temporales.razonSocial !== "" && temporales.codigoPostal !== "")) }>Validar <Icon>published_with_changes</Icon></Button>
                                <Typography variant="body2" sx={{mt: 1}}>Sus datos seran enviados a el SAT para comprobar su validez. Esta operación podria tomar hasta 30 segundos.</Typography>
                            </Grid>
                        ): (
                            <Grid item xs={12}>
                                <Button variant="contained" onClick={facturar} fullWidth disabled={validacion.status === "success"? fetching: true} startIcon={fetching? (<CircularProgress color="inherit"/>): (<Icon>note_add</Icon>)}>Facturar </Button>
                            </Grid>
                        )}
                        
                       
                    </Grid>
                </Box>
            </Box>
        )
    }
    else {
        if(!error) {
            return (
                <LinearProgress variant="query" color="success"/>
            )
        }
        else {
            let val = (Math.floor(Math.random() * 99));
            return (
                
                <Box>
                    <Typography variant="h4">En mantenimiento</Typography>
                    <Typography variant="body1">Estamos trabajando para que puedas facturar lo antes posible.</Typography>
                    <LinearProgress variant="buffer" value={Math.floor(val * 0.16)} valueBuffer={val} color="error"/>
                </Box>
            )
        }
        
    }
    
}