import React from "react";
import { useParams, useNavigate } from "react-router-dom";


import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Icon from '@mui/material/Icon';
import Alert from '@mui/material/Alert';

import LinearProgress from '@mui/material/LinearProgress';

import moment from "moment";
import 'moment/locale/es'
// const moment = require("moment");



export default function Recuperar() {
    let navigate = useNavigate();
    let { orderId, facturaId, rfc } = useParams();
    let [facturaDetails, setFacturaDetails] = React.useState({
        data: {},
        error: false,
        loading: false,
    });
    let [inputRFC, setInputRFC] = React.useState("");
    let [tempRFC, setTempRFC] = React.useState(undefined);
    let [errorDetails, setErrorDetails] = React.useState({
        error: false,
        message: "",
    });

    const isNumber = (value) => {
        return /^\d+$/.test(value);
    }

    moment.locale("es-mx");

    React.useEffect(() => {
        fetchFactura();
    }, [tempRFC]);

    const fetchFactura = async() => {
        console.log("ff: ", orderId, (rfc || inputRFC))
        if(orderId && (rfc || inputRFC)) {

            fetch(`https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/recoverFactura?orderId=${orderId}&rfc=${(rfc || inputRFC)}`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if(data.status !== "error") {
                        setFacturaDetails({
                            data: data.data,
                            error: false,
                            loading: false,                    
                        });
                    }
                    else {
                        setFacturaDetails({
                            data: {},
                            error: true,
                            loading: false,                    
                        });
                    }
                });
        }
        else {
            setFacturaDetails({
                data: {},
                error: false,
                loading: false,                    
            });
        }
    }

    if(orderId && facturaId && rfc && !facturaDetails.loading && !facturaDetails.error) {
        return (
            <Box>
                <Typography variant="h4" component="h1">
                    Facturación Electrónica 4.0
                </Typography>
                <Alert severity="info" sx={{mb: 2}}>
                    <Typography variant="h6" component="h2">
                        Factura generada por {facturaDetails.data.RFC} {moment(facturaDetails.data.createdAt).fromNow()}.
                        <Typography variant="body1">Pulse los botones para recuperar su factura.</Typography>
                    </Typography>
                </Alert>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth href={`https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/download?type=xml&orderId=${orderId}&facturaId=${facturaId}`} target="_blank"><Icon>text_snippet</Icon> Descargar XML</Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button variant="contained" fullWidth href={`https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/download?type=pdf&orderId=${orderId}&facturaId=${facturaId}`} target="_blank"><Icon>download</Icon> Descargar PDF</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else {
        if(!orderId) {
            return (
                <Box>
                    <Typography variant="h4" component="h1">
                        Facturación Electrónica 4.0
                    </Typography>
                    <Typography variant="body1">
                        Es necesario introducir su numero de orden para continuar
                    </Typography>
                    <TextField fullWidth onBlur={(e) => navigate(`/m/${e.target.value}`)} label="Numero de orden" type="tel"/>
                </Box>
            )
        }
        if(facturaDetails.loading && !facturaDetails.error) {
            return (
                <LinearProgress variant="query" />
            )
        }
        if(!facturaDetails.loading && !facturaDetails.error) {
            const handleRFCBlur = (e) => {
                fetchFactura().then(() => {
                    let rfc = inputRFC;
                    if(rfc.length === 13 || rfc.length === 12) {
                        setTimeout(() => {
                            if(tempRFC === facturaDetails.data.RFC) {
                                navigate(`/recuperar/${orderId}/${facturaId}/${rfc}`);
                            }
                            else {
                                console.log(rfc, facturaDetails.data.RFC)
                                setErrorDetails({
                                    error: true,
                                    message: "La orden fue facturada con otro RFC.",
                                })
                            }
                        }, 1500)
                    }
                    else {
                        setErrorDetails({
                            error: true,
                            message: "El RFC no es válido.",
                        })
                    }
                });
                
            }

            const handleRFCInput = (e) => {
                if(e.target.value.match(/^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/)) {
                    setInputRFC(e.target.value.toUpperCase());
                }
            }

            return (
                <Box>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Recuperar factura de la orden {orderId}
                    </Typography>
                    <Alert severity="success">
                        La factura se encuentra en el sistema. 
                    </Alert>
                    <Typography variant="subtitle1" gutterBottom component="p">
                        Para recuperar su factura es necesario proporcionar la información que utilizo al momento de generar su factura
                    </Typography>
                    <TextField id="outlined-basic" label="RFC" variant="outlined" fullWidth onBlur={handleRFCBlur} onChange={handleRFCInput} value={inputRFC} error={errorDetails.error} helperText={errorDetails.message}/>
                </Box>
            )
        }
        if(facturaDetails.error) {
            const regresar = () => {
                navigate(-1);
            }
            return (
                <Box>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Recuperar factura
                    </Typography>
                    <Alert severity="error">
                        <Typography variant="h6" component="h2">
                            La factura no se encuentra en el sistema.
                        </Typography>
                        <Typography variant="body1">
                            Compruebe que el número de orden y RFC sean correctos.
                        </Typography>
                        
                    </Alert>
                    <Button variant="contained" onClick={regresar} fullWidth sx={{my: 2}}>Regresar</Button>
                </Box>
            )
        }
    }

}