import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Icon from '@mui/material/Icon';


import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

import React from "react"

export default function Tabla(props) {
    let opcional = {
        sx: {
            display: {
                xs: 'none',
                sm: 'table-cell',
            }
        }
    }
    const orderItems = props.data;
    return (
        <Paper elevation={2}>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead sx={{bgcolor: "#cdcdcd"}}>
                        <TableRow>
                            <TableCell {...opcional}><b>Producto</b></TableCell>
                            <TableCell><b>Cantidad</b></TableCell>
                            <TableCell {...opcional}><b>Unidad</b></TableCell>
                            <TableCell><b>Concepto(s)</b></TableCell>
                            <TableCell align="right"><b>Precio unitario</b></TableCell>
                            <TableCell align="right"><b>Total</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderItems.map((item, index) => {
                            const basePrice = (item['unit_price'] / (1 + props.taxRate))
                            const tax = basePrice * props.taxRate
                            return (
                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} rowSpan={3}>
                                        <TableCell {...opcional} component="th" scope="row"> 43211500 </TableCell>
                                        <TableCell>{item['quantity']}</TableCell>
                                        <TableCell {...opcional}>H87 - Pieza</TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle1">{item['item']['title']}</Typography>
                                            {/* <Divider /> */}
                                            <span {...opcional}>
                                                <p>02 - Con objeto de impuesto</p>
                                                <br />
                                                <p><b>Producto ML: </b> {item['item']['id']}</p>
                                                <p><br /><b>Traslados:</b></p>
                                                <p>IVA: 002<br />Base: ${basePrice.toFixed(2)}<br />Tasa: {props.taxRate * 100}%<br />Importe: ${tax.toFixed(2)}</p>
                                            </span>
                                        </TableCell>
                                        <TableCell align="right">${(parseFloat(item['unit_price']) / (1 + props.taxRate)).toFixed(2)}</TableCell>
                                        <TableCell align="right">${(parseFloat(item['unit_price']) / (1 + props.taxRate) * item['quantity']).toFixed(2)}</TableCell>
                                    </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}