import React from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'

import { Link } from 'react-router-dom';

export default function Homepage() {
    let [id, setId] = React.useState('');

    const handleIdInput = (e) => {
        if(e.target.value.length <= 16 && e.target.value.match(/^[0-9]+$/)) {

            setId(e.target.value);
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Facturación electrónica
                </Typography>
                <Paper sx={{p: 2, mb: 2}}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Si desea facturar una compra introduzca el numero de pedido
                    </Typography>
                    <TextField fullWidth id="outlined-basic" label="Número de pedido" type="tel" value={id} onChange={handleIdInput} variant="outlined" InputProps={{
                        endAdornment: (
                            <Button variant="contained" disabled={(id.length !== 16? true: false)}  onClick={() => {
                                window.location.href = '/m/' + id;
                            }}>
                                <Icon>send</Icon>
                            </Button>
                        )
                        }}
                    />
                </Paper>
                <Paper sx={{p: 2}}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Si desea recuperar una factura <Link to="/recuperar">haga clic aqui</Link>
                    </Typography>
                </Paper>
                
                

            </Grid>
        </Grid>
    )
}