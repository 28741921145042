import { Outlet, Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import React from "react";
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';


import logo from '../assets/logo.png';

const drawerWitdh = 240;

const drawerWidth = 240;
const navItems = [
    {
        name: 'Inicio',
        path: '/',
    },
    {
        name: 'Recuperar factura',
        path: '/recuperar',
    },
    {
        name: 'Contacto',
        path: '/contacto',
    }
];

export default function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto', maxWidth: '200px', margin: 'auto', marginTop: "32px" }} />
            <Typography variant="h6">
                
            </Typography>
            <Typography variant="caption" color="primary">cfdi4.0</Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <Link to={item.path} key={item.name} disablePadding style={{textDecoration: 'none', color: "#000"}}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar component="nav">
                <Toolbar>
                    <Button color="inherit" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
                        <Icon>menu</Icon>
                    </Button>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                        Facturacion compuproductos <Typography variant="caption">cfdi4.0</Typography>
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Link to={item.path} style={{textDecoration: 'none'}} key={item.name}>
                                <Button sx={{ color: '#fff' }} variant="text">
                                    {item.name}
                                </Button>
                            </Link>
                            
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: {
                xs: '85vw',
                sm: `85vw`,
                md: `1024px`,
            }}} style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}