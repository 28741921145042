import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OSF from './views/osf3';
import Homepage from './views/homepage';
import Layout from "./components/layout";
import Recuperar from './views/recuperar';
import Middleware from './views/inter';
import React from "react"

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<Homepage />} />
					<Route path="facturar/:id" element={<OSF />} />
					<Route path="facturar" element={<p>Error 45040</p>} />
				</Route>
				<Route path="/recuperar" element={<Layout />}>
					<Route index element={<Recuperar />} />
					<Route path=":orderId" element={<Recuperar />} />
					<Route path=":orderId/:facturaId" element={<Recuperar />} />
					<Route path=":orderId/:facturaId/:rfc" element={<Recuperar />} />
				</Route>
				<Route path="/contacto" element={<Layout />}>
					<Route index element={<p>Contactenos a través de <a href="mailto:facturacion.compuproductos@gmail.com?subject=Necesito%20ayuda%20para%20facturar">correo electronico</a></p>} />
				</Route>
				<Route path="/m/:orderId" element={<Layout />}>
					<Route index element={<Middleware />} />
				</Route>
				<Route path="*" element={
					<React.Fragment>
						<center><h1>404 Not found</h1></center>
						<hr />
						<center>facturacion/1.04.0 (facturas.compuproductos.com)</center>
					</React.Fragment>
				} />
			</Routes>
		</BrowserRouter>
	)
}

export default App;
