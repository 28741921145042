import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

export default function Middleware() {
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [error, setError] = React.useState(false);

    const fetchInvoice = async () => {
        fetch(`https://pacb3vnetnoi2w3irbnhckxc4u0puqjb.lambda-url.us-east-1.on.aws/orderDetails?orderId=${orderId}`)
        .then(res => res.json())
        .then(data => {
            if(data.status === "success") {
                navigate(`/facturar/${orderId}`);
            }
            else if(data.status === "error" && data.message === "La factura ya existe") {
                navigate(`/recuperar/${orderId}/${data.data._doc.facturaId}/`);
            }
            else {
                setError(true);
            }
        });
    }
    React.useEffect(() => {
        fetchInvoice();
    }, [orderId])

    if(!error) {
        return (
            <LinearProgress variant="indeterminate"/>
        )
    }
    else {
        return (
            <Box>
                <Alert severity="error" sx={{mb: 4}}>
                    No se encontraron los detalles del pedido: <b>{orderId}</b>
                </Alert>
                <Typography variant="body1" gutterBottom sx={{mb: 1}}>
                    Compruebe que el número de orden sea correcto.
                </Typography>
                <Button onClick={() => navigate(-1)} variant="contained" fullWidth>Regresar</Button>
            </Box>
        )
    }
    
}