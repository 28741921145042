module.exports = {
    "G01": {
        "name": "Adquisición de mercancias",
        "fisica": true,
        "moral": true,
        "regimenesFiscales": [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "G02": {
        name: "Devoluciones, descuentos o bonificaciones",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626    
        ]
    },
    "G03": {
        name: "Gastos en general",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I01": {
        name: "Construcciones",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I02": {
        name: "Mobilario y equipo de oficina por inversiones",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I03": {
        name: "Equipo de transporte",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I04": {
        name: "Equipo de computo y accesorios",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I05": {
        name: "Dados, troqueles, moldes, matrices y herramental",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I06": {
        name: "Comunicaciones telefónicas",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I07": {
        name: "Comunicaciones satelitales",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "I08": {
        name: "Otra maquinaria y equipo",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            606,
            612,
            620,
            621,
            622,
            623,
            624,
            625,
            626
        ]
    },
    "D01": {
        name: "Honorarios médicos, dentales y gastos hospitalarios.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D02": {
        name: "Gastos médicos por incapacidad o discapacidad",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D03": {
        name: "Gastos funerales.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D04": {
        name: "Donativos.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D05": {
        name: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D06": {
        name: "Aportaciones voluntarias al SAR.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D07": {
        name: "Primas por seguros de gastos médicos.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625,
        ]
    },
    "D08": {
        name: "Gastos de transportación escolar obligatoria.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D09": {
        name: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "D10": {
        name: "Pagos por servicios educativos (colegiaturas)",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605,
            606,
            608,
            611,
            612,
            614,
            607,
            615,
            625
        ]
    },
    "S01": {
        name: "Sin efectos fiscales",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            605,
            606,
            608,
            610,
            611,
            612,
            614,
            616,
            620,
            621,
            622,
            623,
            624,
            607,
            615,
            625,
            626
        ]
    },
    "CP01": {
        name: "Pagos",
        fisica: true,
        moral: true,
        regimenesFiscales: [
            601,
            603,
            605,
            606,
            608,
            610,
            611,
            612,
            614,
            616,
            620,
            621,
            622,
            623,
            624,
            607,
            615,
            625,
            626
        ]
    },
    "CN01": {
        name: "Nómina",
        fisica: true,
        moral: false,
        regimenesFiscales: [
            605
        ]
    }
}